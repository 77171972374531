<template>
  <div
    @click="redirect"
    class="flex-none py-3 px-1">
    <div class="flex flex-col items-center justify-center gap-3">
      <img
        class="rounded-full w-24 h-24 border-pink-400 border-2 object-fill object-center" :src="worship.imageUrl" alt="">
      <img v-if="false" class="w-10 h-10 bottom-16 absolute" src="~@/assets/icons/live.gif" alt="">
      <div class="text-xs text-gray-600 font-semibold mt-1.5">Kebaktian {{ worship.sequence }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryItem',
  props: {
    worship: {
      default: () => {},
      required: true
    }
  },
  computed: {
    isLive () {
      return this?.worship?.sequence === 1
    },
    youtubeVideoId () {
      return this?.worship?.youtubeVideoId
    },
    youtubeThumbnail () {
      return `https://img.youtube.com/vi/${this.youtubeVideoId}/default.jpg`
    }
  },
  methods: {
    redirect () {
      this.$router.push({
        name: 'Worship',
        params: {
          sequence: this?.worship?.sequence
        }
      })
    }
  }
}

</script>

<style lang="scss">

</style>
